import apiClient from "@/api/apiClient";

export const getStorageProducts = async (filters) => {
  const result = await apiClient.get("/api/storage", {
    params: filters,
  });

  return result.data;
};

export const saveStorageImei = async (storageId, imei) => {
  const result = await apiClient.post(`/api/storage/${storageId}`, { imei });
  return result.data;
};
